import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0px;
        padding: 0px;
    }

    body {
        margin: 0px;
        padding: 0px;
        background: #F5F5F5;
    }

		h1,h2,h3,h4,h5 {
        font-weight: bold;
    }

    .bg-white {
        background: white
    }

    .text-white {
        color: white !important;
    }

    .min-text {
        font-size: 12px
    }

    .text-budget {
        
        font-size: 12px;
        height: 300px !important;

    }
`
