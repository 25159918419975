import React, {useEffect, useState, useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import * as yup from "yup"
import {v4 as uuidv4} from 'uuid';

type Product = {
    id: string;
    name: string;
    value: number;
    discount: number;
    children_value: number;
    children_discount: number;
    obs: string;
}

const schema = yup.object().shape({
    name: yup.string().required(),
});

const Products: React.FC = () => {

    const [products, setProducts] = useState<Product[]>([]);

    const {handleSubmit, register, errors} = useForm<Product>({
        resolver: yupResolver(schema)
    })

    const onSubmit = (data: Product) => {
        console.log(data)
        const newProduct = {id: uuidv4(), ...data};
        products.push(newProduct);
        console.log(products)
        setProducts(products);
        localStorage.setItem('products', JSON.stringify(products));
    };

    const loadProducts = useCallback(() => {
        const loadedProducts = localStorage.getItem('products');
        console.log("Produtos carregados", loadedProducts)
        if (loadedProducts != null)
            setProducts(JSON.parse(loadedProducts));
    }, []);

    useEffect(() => {
        console.log(products)
    }, [products])

    useEffect(() => {
        loadProducts();
    }, []);

    function deleteItem(id: string) {
        const newProducts = products.filter(product => (product.id != id));
        setProducts(newProducts)
        localStorage.setItem('products', JSON.stringify(newProducts));

    }

    return (
        <div className='p-3'>
            <h3>Novo Produto</h3>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className='row'>
                    <div className="col-sm-4">
                        <input
                            type="text"
                            name='name'
                            ref={register}
                            required
                            className='form-control mb-3'
                            placeholder="Nome do Produto" />
                    </div>
                    <div className="col-sm-4">
                        <input
                            type="text"
                            name='value'
                            ref={register}
                            required
                            className='form-control mb-3'
                            placeholder="Valor do Produto" />
                    </div>
                    <div className="col-sm-4">
                        <input
                            type="text"
                            name='discount'
                            ref={register}
                            className='form-control mb-3'
                            required
                            placeholder="Desconto" />
                    </div>
                    <div className="col-sm-4">
                        <input
                            type="text"
                            name='children_value'
                            ref={register}
                            required
                            className='form-control mb-3'
                            placeholder="Valor Criança" />
                    </div>
                    <div className="col-sm-4">
                        <input
                            type="text"
                            name='children_discount'
                            ref={register}
                            className='form-control mb-3'
                            required
                            placeholder="Desconto Criança" />
                    </div>

                    <div className="col-sm-12">
                        <input
                            type="text"
                            name='obs'
                            ref={register}
                            className='form-control mb-3'
                            placeholder="Observação" />
                    </div>
                    <div className="col-sm-12 mb-4">
                        <button
                            type="submit" className='btn btn-primary'>
                            Cadastrar Produto
                        </button>
                        <hr />
                    </div>
                </div>
            </form>

            <h3>Lista de Produtos</h3>
            <table className='table table-dashed table-bordered bg-white' >
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Valor</th>
                        <th>Desconto</th>
                        <th>OBS</th>
                        <th></th>
                    </tr>
                </thead>
                {products.map(product => {
                    return (
                        <tr>
                            <td>{product.name}</td>
                            <td>R$ {product.value}/{product.children_value}</td>
                            <td>R$ {product.discount}/{product.children_discount}</td>
                            <td>{product.obs}</td>
                            <td><a href="#" onClick={() => {deleteItem(product.id)}}>X</a></td>
                        </tr>
                    )
                })}
            </table >
            <hr />

        </div >
    )

}

export default Products;
