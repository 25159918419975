import React, { useState } from 'react';
import Budget from './components/budget';
import Products from './components/products'

function App() {
    const [activePage, setActivePage] = useState('budget');


    return (
        <div className="App">
            <nav className="navbar navbar-expand-lg navbar-light bg-primary text-white">
                <a className="navbar-brand text-white" href="#">Controle de <b>Orçamentos</b></a>
            </nav>
            <div className='container mt-4'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Orçamento</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Produtos</a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"><Budget /></div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"><Products /></div>
                </div>
            </div>
        </div >
    );
}

export default App;
