import React, { useEffect, useState, useCallback, useRef } from 'react';

type Product = {
    id: string;
    name: string;
    value: number;
    discount: number;
    obs: string;
    children_value: number;
    children_discount: number;
}

type Item = {
    id: string;
}

const Budget: React.FC = () => {
    const [products, setProducts] = useState<Product[]>([]);
    const [items, setItems] = useState<Item[]>([]);
    const [currentItem, setCurrentItem] = useState("0");
    const [text, setText] = useState("");
    const [qtd, setQtd] = useState<number>(0);
    const [childrenQtd, setChildrenQtd] = useState<number>(0);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    function insertItem() {
        console.log(currentItem)
        const item = {
            id: currentItem
        }
        setItems([...items, item]);
    }

    function getProduct(id: string): Product {
        const list = products.filter(product => product.id == id);
        return list[0];
    }

    const loadProducts = useCallback(() => {
        const loadedProducts = localStorage.getItem('products');
        if (loadedProducts != null)
            setProducts(JSON.parse(loadedProducts));
    }, []);

    useEffect(() => {
        console.log(products)
        updateText()
    }, [products, items, qtd, childrenQtd])

    function updateText() {
        const childrenText = childrenQtd > 0 ? ' e ' + childrenQtd + ' criança' + (childrenQtd == 1 ? '' : 's') : '';
        let currentText = `Orçamento para ${qtd} adulto${qtd == 1 ? '' : 's'}${childrenText}. \nValores promocionais para pagamento em espécie.\n`;
        let totalValue = 0;
        let discount = 0;
        let totalValueWithDiscount = 0;
        items.map(item => {
            const product = getProduct(item.id);
            currentText += `\n${product.name}\n R$ ${((product.value * qtd) + (product.children_value * childrenQtd)).toFixed(2).replace('.', ',')} ${product.obs}  \n`;
            totalValue += (product.value) * qtd + product.children_value * childrenQtd;
            discount += (product.discount) * qtd + product.children_discount * childrenQtd;
        });
        console.log(currentText);

        currentText += "\nValor normal do pacote R$ " + (totalValue + discount).toFixed(2).replace('.', ',');
        currentText += "\nDesconto já aplicado R$ " + discount.toFixed(2).replace('.', ',');
        currentText += "\nTotal R$ " + totalValue.toFixed(2).replace('.', ',');
        setText(currentText);
    }

    useEffect(() => {
        loadProducts();
    }, []);

    function deleteItem(index: number) {
        setItems(items.filter((item, i) => i != index))
    }

    function copyText() {
        textAreaRef?.current?.select();
        document.execCommand('copy');
    }

    return (
        <div className='p-3'>
            <div className='row'>
                <div className="col-sm-4">
                    <label htmlFor="qtd">Quantidade de Pessoas</label>
                    <input
                        type="text"
                        name='qtd'
                        onChange={(e) => {
                            console.log(e.target.value);
                            setQtd(parseInt(e.target.value));
                        }}
                        required
                        className='form-control mb-3'
                        placeholder="Quantidade de Adultos" />
                    <input
                        type="text"
                        name='childrenQtd'
                        onChange={(e) => {
                            console.log(e.target.value);
                            setChildrenQtd(parseInt(e.target.value));
                        }}
                        required
                        className='form-control mb-3'
                        placeholder="Quantidade de Crianças" />
                </div>

            </div>
            <hr />
            <h5>Adicionar Passeios</h5>
            <div className='row'>
                <div className="col-sm-4">
                    <label htmlFor="qtd">Passeio</label>
                    <select
                        name='item'
                        onChange={(e) => {
                            console.log(e.target.value);
                            setCurrentItem(e.target.value);
                        }}
                        required
                        className='form-control mb-3' >
                        <option value="0">Selecione um Passeio</option>
                        {products.map(product => {
                            return (
                                <option value={product.id}>{product.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-sm-4">
                    {(<button className='btn btn-primary' onClick={insertItem}><b>+</b> Adicionar</button>)}
                </div>
            </div >
            <table className="table table-dashed min-text mt-4">
                <thead>
                    <tr>
                        <th>Produto</th>
                        <th>Valor</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {items != null && items.map((item, index) => {
                        const product = getProduct(item.id)
                        console.log(item)
                        return (<tr>
                            <td>{product.name}</td>
                            <td>R$ {((product.value * qtd + product.children_value * childrenQtd)).toFixed(2).replace('.', ',')}</td>
                            <td><a href="#" onClick={() => { deleteItem(index) }}>X</a></td>
                        </tr>)
                    })}

                </tbody>
            </table>
            <button className='btn btn-info mb-2 btn-block' onClick={copyText}>Copiar Texto</button>
            <textarea className='form-control text-budget' ref={textAreaRef} value={text}></textarea>
        </div >
    )
}

export default Budget;
